import { getCurrentInstance } from '@vue/composition-api'

export default function useAccountVerification() {
  const { proxy } = getCurrentInstance()
  // 上传文件
  const uploadFile = async file => {
    const formdata = new FormData()
    formdata.append('file', file)
    const res = await proxy.$api.upload(formdata)
    const { code, data } = res.data
    if (code === 200) {
      const { path, domain } = data
      return domain + path
    }
    return ''
  }
  return { uploadFile }
}
